export function Introduction(){
    return(
        <div id="intro-main" className="mx-auto">
            <div id="intro-image">                
                <img src="/images/aboutme.jpg" alt="intro-profile"/>
            </div>
            <div id="intro-content">
                <div id="about-me">
                    <h2 id="am-name">Patricia Lin</h2>
                    <h4 id="am-title">FRONT END DEVELOPER</h4>
                    <p id="am-desc">I am a web developer. I am a web developer. I am a web developer.</p>
                    <div id="social-links">
                        <span className="sl-icons"><i className="fa-brands fa-github"></i><i className='sl-text'>@tricialimc</i></span>
                        <span className="sl-icons"><i className="fa-brands fa-linkedin"></i><i className='sl-text'>@tricia-lin</i></span>
                        <span className="sl-icons"><i className="fa-solid fa-envelope"></i><i className='sl-text'>Patricia.limcangco@gmail.com</i></span>
                    </div>
                </div>
                
                
            </div>

        </div>
    );
    

}