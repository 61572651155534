import './App.css';
import {Introduction} from './components/Introduction.js'

function App() {
  return (
    <div id="main" className="container">
      <Introduction/>

    </div>
    
  );
}

export default App;
